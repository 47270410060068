<template>
  <div class="w-full flex flex-col h-full">
    <!-- Header -->
    <div
      v-if="false"
      class="flex sm:flex-row flex-col gap-3"
      :class="{
        'px-5 py-3 md:pt-4': !paddingless,
      }"
    >
      <div class="flex flex-col gap-1 sm:flex-1">
        <fw-heading
          :size="'lg'"
          :muted="true"
          :counter="loading ? 0 : channels.length"
          :counter-total="loading ? 0 : totalResults"
        >
          {{ $t('channels') }}
        </fw-heading>
      </div>
      <div class="flex gap-1 items-center"></div>
    </div>
    <div
      v-if="false"
      :class="{
        'px-5 pb-3 md:px-4': !paddingless,
      }"
    >
      <ContextualSearch
        type="minimal"
        :filter-options="filterOptions"
        :loading="loading"
        :applied-filters="appliedFilters"
        :show-filters="false"
        @search="search"
      />
    </div>
    <div
      class="flex-1 flex flex-col pt-3 h-full overflow-y-auto"
      :class="{
        'px-5': !paddingless,
      }"
    >
      <div
        v-if="loading || (metrics.length == 0 && query)"
        class="text-sm text-gray-500 py-10 text-center flex justify-center items-center h-full"
      >
        <div v-if="loading" class="animate-pulse">A carregar...</div>
        <div v-else>
          Sem filtros correspondentes
        </div>
      </div>
      <div v-else class="h-full overflow-y-auto w-full flex flex-col gap-1">
        <RecordChannel
          v-for="channel in metrics"
          :key="channel.id"
          :selected="selected.includes(channel.key)"
          :record="channel"
          @click.native="$emit('select', channel.key)"
        />
      </div>
    </div>
    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged($event)"
    />
  </div>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import RecordChannel from '../records/RecordChannel.vue'
export default {
  components: {
    ContextualSearch,
    BlockPagination,
    RecordChannel,
  },

  props: {
    type: {
      type: String,
      default: 'full',
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 30,
    },
    filterType: {
      //by default show all channels types
      type: String,
      default: null,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    metrics: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      //filterType: 'inbox',
      filterOptions: [
        {
          key: 'type',
          label: 'Tipo',
          options: [
            {
              key: 'all',
              label: 'Todos',
            },
            {
              key: 'electricity',
              label: 'Electricidade',
            },
            {
              key: 'water',
              label: 'Água',
            },
            {
              key: 'gas',
              label: 'Gás',
            },
            {
              key: 'heat',
              label: 'Aquecimento',
            },
            {
              key: 'other',
              label: 'Outros',
            },
          ],
        },
      ],
      appliedFilters: [],
      totalPages: 1,
      totalResults: 0,
      currentResults: 0,
      page: 1,
      query: null,
      stats: null,
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    language() {
      return this.$store.state.language || this.$i18n.locale
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "channels": "Canais",
      "inbox": "Caixa de entrada",
      "archive":"Arquivo",
      "settings":"Configurações",
      "active": "Activo",
      "inactive": "Inactivo",
      "partial": "Parcial",
      "unread": "Por ler",
      "archiveAll": "Arquivar todas",
      "markAsRead": "Marcar todas como lidas"
    },
    "en": {
      "channels": "Channels",
      "inbox": "Inbox",
      "archive":"Archive",
      "settings":"Settings",
      "active": "Active",
      "inactive": "Inactive",
      "partial": "Partial",
      "unread": "Unread",
      "archiveAll": "Archive all",
      "markAsRead": "Mark all as read"
    }
  }
  </i18n>
