<template>
  <div class="flex bg-white cursor-pointer select-none rounded-md px-3 py-2 gap-3 items-center">
    <div
      class="border rounded-md h-5 w-5 border-teal-500 text-white flex flex-col justify-center items-center"
      :class="{
        'bg-teal-500 bg-opacity-80': selected,
      }"
    >
      <fw-icon-check v-if="selected" class="h-4 w-4" />
    </div>
    <div class="flex-1 line-clamp-1 font-medium text-base">
      {{ record.title }}
    </div>
    <div>
      <fw-icon-electricity-fill v-if="metricKey == 'electricity:consumption'" class="w-5 h-5 text-gray-300" />
      <fw-icon-water-fill v-else-if="metricKey == 'water:consumption'" class="w-5 h-5 text-gray-300" />
      <fw-icon-leaf v-else-if="metricKey == 'electricity:production'" class="w-5 h-5 text-gray-300" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'RecordChannel',
  props: {
    record: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    metricKey() {
      return `${this.record.type}:${this.record.category}`
    },
  },
}
</script>
