<template>
  <fw-layout full back-to="-1" mobile-ready>
    <template #main-content>
      <div :key="language" class="px-5 max-w-7xl mx-auto">
        <div class="flex flex-col md:flex-row sticky top-0 bg-gray-50 bg-opacity-80 backdrop-blur-lg py-3 gap-3">
          <div class="flex-1 flex items-center">
            <div class="flex-1">
              <fw-panel-page-header v-if="dashboard" :title="dashboard.title[language]" />
            </div>
            <fw-button
              class="sm:hidden"
              type="xlight"
              :loading="loading"
              :rounded="isMobile ? 'full' : 'left'"
              size="sm"
              @click.native="refresh()"
            >
              <template #icon>
                <fw-icon-refresh class="w-4 h-4 text-gray-800" />
              </template>
              <template #default>
                <span class="hidden sm:inline-block">Atualizar</span>
              </template>
            </fw-button>
          </div>
          <div class="flex gap-3">
            <div class="flex items-center">
              <fw-button
                class="mr-3"
                type="xlight"
                size="sm"
                :counter="selectedMetrics.length == totalChannels ? 0 : selectedMetrics.length"
                :counter-total="totalChannels"
                @click.native="filterData()"
              >
                <template #icon>
                  <fw-icon-filter class="w-4 h-4 text-gray-800" />
                </template>
                <span class="hidden sm:block">Filtros</span>
              </fw-button>
              <fw-button type="xlight" rounded="left" size="sm" @click.native="setTimeFrame(1, 'day')">
                <span
                  :class="{
                    'text-gray-800': timeFrameUnit !== 'day',
                    'text-primary': timeFrameUnit === 'day',
                  }"
                >
                  1 dia
                </span>
              </fw-button>
              <fw-button type="xlight" rounded="none" size="sm" @click.native="setTimeFrame(1, 'week')">
                <span
                  :class="{
                    'text-gray-800': timeFrameUnit !== 'week',
                    'text-primary': timeFrameUnit === 'week',
                  }"
                >
                  1 sem
                </span>
              </fw-button>
              <fw-button type="xlight" rounded="none" size="sm" @click.native="setTimeFrame(1, 'month')">
                <span
                  :class="{
                    'text-gray-800': timeFrameUnit !== 'month',
                    'text-primary': timeFrameUnit === 'month',
                  }"
                >
                  1 mês
                </span>
              </fw-button>
              <fw-button type="xlight" rounded="none" size="sm" @click.native="setTimeFrame(1, 'year')">
                <span
                  :class="{
                    'text-gray-800': timeFrameUnit !== 'year',
                    'text-primary': timeFrameUnit === 'year',
                  }"
                >
                  1 ano
                </span>
              </fw-button>
              <fw-button type="xlight" size="sm" rounded="right" @click.native="showCustomTimeframe = true">
                <template #icon>
                  <fw-icon-calendar class="w-4 h-4 text-gray-800" />
                </template>
                <span v-if="dates.length == 0">Personalizado</span>
                <span v-else> {{ formatDate(dates[0], true) }} - {{ formatDate(dates[1], true) }} </span>
              </fw-button>
              <b-dropdown v-if="currentInterval" aria-role="list" position="is-bottom-left">
                <template #trigger>
                  <fw-button type="xlight" rounded="right" class="opacity-75 hover:opacity-100 flex items-center gap-2">
                    <div class="flex items-center gap-2">
                      <span class="inline-block h-2 w-2 mr-2 rounded-full bg-gray-300"></span>
                      <span>{{ currentInterval }}</span>
                    </div>
                  </fw-button>
                </template>
                <b-dropdown-item v-for="(interval, i) in availableIntervals" :key="i" paddingless aria-role="listitem">
                  <fw-button
                    type="basic-action"
                    size="sm"
                    expanded
                    left
                    :label="interval"
                    @click.native="changeInterval(interval)"
                  >
                    {{ interval }}
                  </fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="items-center hidden sm:flex">
              <fw-button
                type="xlight"
                :loading="loading"
                :rounded="isMobile ? 'full' : 'left'"
                size="sm"
                @click.native="refresh()"
              >
                <template #icon>
                  <fw-icon-refresh class="w-4 h-4 text-gray-800" />
                </template>
                <template #default>
                  <span class="hidden sm:inline-block">Atualizar</span>
                </template>
              </fw-button>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button type="xlight" rounded="right" class="opacity-75 hover:opacity-100 flex items-center gap-2">
                    {{ autoRefresh || 'Off' }} <fw-icon-chevron-down class="h-4 w-4"></fw-icon-chevron-down>
                  </fw-button>
                </template>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="basic-action"
                    size="sm"
                    expanded
                    left
                    label="Off"
                    @click.native="activateAutoRefresh('off')"
                  >
                    Off
                  </fw-button>
                </b-dropdown-item>
                <b-dropdown-item v-for="(option, o) in refreshOptions" :key="o" paddingless aria-role="listitem">
                  <fw-button
                    type="basic-action"
                    size="sm"
                    expanded
                    left
                    :label="option.label"
                    @click.native="activateAutoRefresh(option)"
                  >
                    {{ option.label }}
                  </fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <ContentLoader v-if="loading" />
        <div v-else-if="metricGroupKeys.length > 0">
          <div class="mb-5 text-base hidden">
            Esta é uma descrição
          </div>
          <div
            v-if="
              summary &&
                (summary['electricity_consumption'] || summary['electricity_production'] || summary['water']) &&
                totalChannels == selectedMetrics.length &&
                isIntervalWithSummary
            "
            class="bg-white rounded-lg grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 border border-gray-100 mb-5"
          >
            <BlockSummary
              v-if="summary['electricity_consumption']"
              class="border-r border-gray-100"
              summary-key="electricity_consumption"
              :summary="summary"
              :time-frame="timeFrameUnit ?? 'week'"
            />
            <BlockSummary
              v-if="summary['electricity_production']"
              class="border-r border-gray-100"
              summary-key="electricity_production"
              :summary="summary"
              :time-frame="timeFrameUnit ?? 'week'"
              :color="'#34eb89'"
            />
            <BlockSummary
              v-if="summary['water']"
              summary-key="water"
              :summary="summary"
              :time-frame="timeFrameUnit ?? 'week'"
              :color="'#34a5eb'"
            />
          </div>
          <DashboardPanel
            v-for="metricKey in metricGroupKeys"
            :key="'metric_' + metricKey"
            class="mb-5"
            :metrics="metricGroups[metricKey]"
            :language="language"
            :catalog-key="dashboardKey"
            :app-key="'dashboard'"
          />
        </div>
      </div>
    </template>
    <template #modals>
      <fw-modal
        v-if="showCustomTimeframe"
        :active.sync="showCustomTimeframe"
        can-cancel
        title="Período personalizado"
        @close="showCustomTimeframe = false"
      >
        <fw-label>Escolha a data de início e fim do período que pretende:</fw-label>
        <b-datepicker
          v-model="dates"
          class="datepicker-noshadow"
          placeholder="Selecione o período que pretende"
          :min-date="minDate"
          :max-date="maxDate"
          range
          inline
        >
        </b-datepicker>
        <div class="flex items-center gap-4 text-lg transition-all pt-3 border-t border-gray-100 flex-shrink-0">
          <div class="flex-1 text-gray-500"></div>
          <fw-button class="opacity-50 hover:opacity-80" @click.native="resetDates()">
            Cancelar
          </fw-button>
          <fw-button type="primary" :disabled="!datesCorrect" :loading="loading" @click.native="applyDates()">
            Aplicar
          </fw-button>
        </div>
      </fw-modal>
      <fw-modal
        v-if="showDataFilters"
        :active.sync="showDataFilters"
        can-cancel
        title="Filtros de dados"
        @close="showDataFilters = false"
      >
        Seleccione os canais que pretende visualizar:
        <PanelChannelsList
          :metrics="metrics"
          :selected="tmpSelectedMetrics"
          paddingless
          @select="changeSelected($event)"
        />
        <div class="flex items-center gap-4 text-lg transition-all pt-3 border-t border-gray-100 flex-shrink-0">
          <div class="flex-1 text-gray-500"></div>
          <fw-button class="opacity-50 hover:opacity-80" @click.native="cancelFilters()">
            Cancelar
          </fw-button>
          <fw-button type="primary" :loading="loading" @click.native="applyFilters()">
            Aplicar
          </fw-button>
        </div>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import dayjs from 'dayjs'
import ContentLoader from '../components/utilities/ContentLoader'
import DashboardPanel from '../components/panels/DashboardPanel'
import ServiceAnalytics from '../services/ServiceAnalytics'
import BlockSummary from '../components/blocks/BlockSummary'
import PanelChannelsList from '../components/panels/PanelChannelsList'
const monthsMap = {
  pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
}

export default {
  components: {
    ContentLoader,
    DashboardPanel,
    BlockSummary,
    PanelChannelsList,
  },
  data() {
    return {
      showCustomTimeframe: false,
      showDataFilters: false,
      loading: false,
      dashboard: null,
      metrics: [],
      selectedMetrics: [],
      tmpSelectedMetrics: [],
      dates: [],
      metricGroups: {},
      info: {},
      filters: {},
      refreshOptions: [
        {
          label: '15m',
          value: 900000,
        },
        {
          label: '30m',
          value: 1800000,
        },
        {
          label: '1h',
          value: 3600000,
        },
        {
          label: '2h',
          value: 7200000,
        },
      ],
      autoRefreshTimer: null,
      autoRefresh: '',
      timeFrameUnit: null,
      timeFrameValue: null,
      currentInterval: null,
      availableIntervals: [],
      summary: {},
      labels: {},
    }
  },
  computed: {
    isIntervalWithSummary() {
      return (
        (this.dates.length == 0 && !this.timeFrameUnit && !this.timeFrameValue) ||
        (this.dates.length == 0 &&
          (this.timeFrameUnit == 'day' ||
            this.timeFrameUnit == 'week' ||
            this.timeFrameUnit == 'month' ||
            this.timeFrameUnit == 'year') &&
          this.timeFrameValue == 1)
      )
    },
    isMobile() {
      return window.innerWidth < 600
    },
    totalChannels() {
      return this.metrics.length
    },
    datesCorrect() {
      return this.dates.length == 2
    },
    maxDate() {
      return new Date()
    },
    minDate() {
      return dayjs()
        .subtract(1, 'year')
        .toDate()
    },
    metricGroupKeys() {
      return Object.keys(this.metricGroups)
    },
    rightSideWidth() {
      return window.innerWidth > 1023 ? 'w-1/2' : 'w-full'
    },
    dashboards() {
      return this.$store.state.dashboards
    },
    dashboards_steps() {
      /*return this.dashboards.map(dashboard => {
        return {
          value: dashboard.key,
          text: dashboard.title,
        }
      })*/
      return []
    },
    language() {
      return this.$store.state.language || this.$i18n.locale
    },
    dashboardKey() {
      return this.$route.params.id
    },
    api() {
      return this.$store.state.api.base
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    changeInterval(interval) {
      this.currentInterval = interval
      this.getDashboard()
    },
    filterData() {
      this.showDataFilters = true
      this.tmpSelectedMetrics = JSON.parse(JSON.stringify(this.selectedMetrics))
    },
    applyFilters() {
      this.selectedMetrics = this.tmpSelectedMetrics
      this.showDataFilters = false
      this.calculcateMetricGroups()
      //this.getDashboard()
    },
    changeSelected(value) {
      console.log('changeSelected', value)
      if (this.tmpSelectedMetrics.includes(value)) {
        this.tmpSelectedMetrics = this.tmpSelectedMetrics.filter(item => item !== value)
        return
      }
      this.tmpSelectedMetrics.push(value)
    },
    cancelFilters() {
      this.showDataFilters = false
    },
    resetDates() {
      this.dates = []
      this.showCustomTimeframe = false
    },
    applyDates() {
      if (!this.datesCorrect) return
      this.showCustomTimeframe = false
      this.timeFrameValue = null
      this.timeFrameUnit = null
      this.currentInterval = null
      this.getDashboard()
    },
    setTimeFrame(value, unit) {
      this.dates = []
      if (this.timeFrameUnit == unit) {
        this.timeFrameValue = null
        this.timeFrameUnit = null
        this.currentInterval = null
        this.getDashboard()
        return
      }
      this.currentInterval = null
      this.timeFrameValue = value
      this.timeFrameUnit = unit
      this.getDashboard()
    },
    activateAutoRefresh(option) {
      if (option === 'off') {
        this.autoRefresh = ''
        clearInterval(this.autoRefreshTimer)
        return
      }

      this.autoRefresh = option.label
      if (this.autoRefreshTimer != null) {
        clearInterval(this.autoRefreshTimer)
      }
      this.autoRefreshTimer = setInterval(() => {
        this.getDashboard()
      }, option.value)
    },
    refresh() {
      this.getDashboard()
    },
    calculcateMetricGroups() {
      let metricGroups = {}
      this.metrics
        .filter(el => this.selectedMetrics.includes(el.key))
        .forEach(metric => {
          if (!metricGroups[metric.type + ':' + metric.category]) metricGroups[metric.type + ':' + metric.category] = []
          metricGroups[metric.type + ':' + metric.category].push(metric)
        })
      this.metricGroups = metricGroups
    },
    changeSelectedMetrics(channelId) {
      console.log('changeSelectedMetrics', channelId)
      if (this.selectedMetrics.includes(channelId)) {
        this.selectedMetrics = this.selectedMetrics.filter(item => item !== channelId)
        return
      }
      this.selectedMetrics.push(channelId)
      this.calculcateMetricGroups()
    },
    async getDashboard() {
      this.loading = true
      try {
        let params = {}
        if (this.currentInterval) {
          params['interval'] = this.currentInterval
        }
        if (this.timeFrameUnit && this.timeFrameValue) {
          params['time_frame_unit'] = this.timeFrameUnit
          params['time_frame_value'] = this.timeFrameValue
          params['summary'] = true
        } else if (this.dates.length == 0) {
          params['time_frame_unit'] = 'week'
          params['time_frame_value'] = 1
          params['summary'] = true
        } else {
          //chage hours to midnight
          this.dates[0].setHours(0, 0, 0, 0)
          this.dates[1].setHours(0, 0, 0, 0)
          params['time_start'] = Math.ceil(this.dates[0].getTime() / 1000)
          params['time_end'] = Math.ceil(this.dates[1].getTime() / 1000)
        }
        let id = this.dashboardKey
        let result = await ServiceAnalytics.getDashboard(id, params)
        let metrics = []
        Object.keys(result.data).forEach(key => {
          metrics.push({
            key: key,
            title: result.labels.channels[key][this.language],
            type: result.labels.channels[key].type,
            category: result.labels.channels[key].category,
            data: result.data[key],
          })
        })
        this.summary = result.summary
        this.metrics = metrics
        this.selectedMetrics = metrics.map(metric => metric.key)
        this.info = result.info
        this.filters = result.filters
        this.dashboard = result.dashboard
        this.currentInterval = result.current_interval
        this.availableIntervals = result.available_intervals
        this.labels = result.labels
        this.calculcateMetricGroups()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    dayjs(value) {
      return dayjs(value)
    },
    openView(view) {
      if (view === 'dashboards') this.$router.push({ name: 'dashboards' })
      else this.$router.push({ name: 'dashboard-details', params: { code: view } })
    },

    formatDate(value, withYear = false) {
      if (!value) return ''
      let date = dayjs(String(value))
      let month = monthsMap[this.language][date.toDate().getMonth()]
      let year = new Date().getFullYear() !== date.toDate().getFullYear() || withYear ? ' ' + date.format('YYYY') : ''
      return date.format('D') + ' ' + month + year
    },

    /*async getDashboard() {
      console.log('getDashboard')
      this.loading = true
      try {
        let result = await this.api.getCatalogue(this.dashboardKey)
        console.log('DASHBOARD', result)
        this.dashboard = result
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },*/
  },
}
</script>
<i18n>
{
  "pt": {
    "dashboardNotAvailable": "Dashboard não disponível"
  },
  "en": {
    "dashboardNotAvailable": "Dashboard not available"
  }
}
</i18n>
<style>
.datepicker-noshadow .datepicker-table {
  box-shadow: none;
}
.datepicker-noshadow .datepicker .dropdown-content {
  box-shadow: none !important;
}
.datepicker-noshadow .dropdown-content {
  box-shadow: none !important;
  border: #dddddd 1px solid;
}
</style>
