<template>
  <div class="dashboard_panel bg-white rounded-xl border border-gray-100">
    <div class="flex border-b border-gray-100 px-5 py-4 items-center gap-5">
      <fw-icon-electricity-fill v-if="metricKey == 'electricity:consumption'" class="w-6 h-6 text-gray-300" />
      <fw-icon-water-fill v-else-if="metricKey == 'water:consumption'" class="w-6 h-6 text-gray-300" />
      <fw-icon-leaf v-else-if="metricKey == 'electricity:production'" class="w-6 h-6 text-gray-300" />
      <div class="flex-1 font-bold text-base">
        {{ labels[metricKey] }}
      </div>
      <div class="flex items-center">
        <fw-button
          v-if="!timeseries"
          :class="{ 'text-primary': graphView == 'numbers' }"
          label="Métricas apenas"
          @click.native="changeGraphView('numbers')"
        >
          <span class="font-bold text-sm">123</span>
        </fw-button>
        <fw-button
          v-if="!timeseries"
          :class="{ 'text-primary': graphView == 'bars' }"
          label="Gráfico de barras"
          @click.native="changeGraphView('bars')"
        >
          <svg
            class="fill-current w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M2 13h6v8H2v-8zm14-5h6v13h-6V8zM9 3h6v18H9V3zM4 15v4h2v-4H4zm7-10v14h2V5h-2zm7 5v9h2v-9h-2z" />
          </svg>
        </fw-button>
        <fw-button
          v-if="!timeseries && allowCircleGraph"
          :class="{ 'text-primary': graphView == 'circle' }"
          label="Ver lista com descrição"
          @click.native="changeGraphView('circle')"
        >
          <svg
            class="fill-current w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z"
            />
          </svg>
        </fw-button>
      </div>
      <div class=" text-sm text-gray-700 ">
        <b-dropdown v-if="false" ref="submetricOptions" max-height="300" aria-role="list" position="is-bottom-left">
          <fw-button
            slot="trigger"
            aria-label="Escolher métrica"
            type="dropdown"
            size="sm"
            class="flex gap-1 items-center"
          >
            <svg
              v-if="showDateRange"
              class="fill-current h-5 w-5 mr-1 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
            </svg>
            <div v-if="showDateRange" class="flex">
              <div v-if="dates.length > 0">{{ dates[0] | formatDate }}</div>
              <div v-else>oo</div>
              <div class="w-2">-</div>
              <div v-if="dates.length > 0">{{ dates[1] | formatDate }}</div>
              <div v-else>oo</div>
            </div>
            <div v-if="showDateRange" class="bg-gray-300 h-1.5 w-1.5 rounded-full mx-1"></div>
            {{
              showAllDataSameTime
                ? $t('all')
                : currentSubmetric !== null && currentSubmetric['title'][language]
                ? currentSubmetric['title'][language]
                : $t('no_translation')
            }}
            <fw-icon-chevron-down class="w-4 h-4" />
          </fw-button>

          <b-dropdown-item
            v-if="false"
            :key="'submetrics_options'"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-label class="ml-4">{{ $t('available_metrics') }}</fw-label>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="false && metric.extrametrics.length > 0"
            aria-role="menu-item"
            :focusable="true"
            class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
            @click.native="seeAllData()"
          >
            <div
              class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
              :class="{
                'bg-primary bg-opacity-90': showAllDataSameTime,
                'bg-gray-200': !showAllDataSameTime,
              }"
            ></div>
            <div>{{ $t('all') }}</div>
          </b-dropdown-item>

          <b-dropdown-item
            v-for="(submetric_option, a) in []"
            :key="'submetric_selector_' + a"
            aria-role="menu-item"
            :focusable="true"
            class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
            @click.native="selectMetric(submetric_option)"
          >
            <div
              class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
              :class="{
                'bg-primary bg-opacity-90':
                  currentSubmetric && currentSubmetric.key === submetric_option.key && !showAllDataSameTime,
                'bg-gray-200':
                  currentSubmetric && currentSubmetric.key !== submetric_option.key && !showAllDataSameTime,
              }"
            ></div>
            <div>{{ submetric_option['title'][language] }}</div>
          </b-dropdown-item>

          <div v-if="showDateRange" class="mb-5" style="min-width:245px">
            <fw-label class="ml-4">Período temporal</fw-label>
            <div class="flex ">
              <div class="flex w-full mx-4 gap-2">
                <div
                  class="py-1.5 flex-1 text-center justify-around rounded-md border  flex text-sm cursor-pointer px-2"
                  :class="{
                    'text-gray-500 border-gray-200 font-semibold': dates.length == 0,
                    'text-primary border-primary font-bold': dates.length > 0,
                  }"
                  @click="toogleDateRangePicker"
                >
                  <div v-if="dates.length > 0">{{ dates[0] | formatDate }}</div>
                  <faicon v-else icon="infinity" class="text-gray-400 h-5 w-5 self-center justify-center"></faicon>
                  <div class="w-2">-</div>
                  <div v-if="dates.length > 0">{{ dates[1] | formatDate }}</div>
                  <div v-else>{{ 'Hoje' }}</div>
                </div>
                <div
                  v-if="dates.length > 0"
                  class="self-center h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-800"
                  @click="deleteDateRange"
                >
                  <svg
                    class="fill-current h-5 w-5  "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="relative">
              <div v-show="showDateRangePicker" class="absolute top-0 right-2 z-10">
                <b-datepicker v-model="dates" inline range trap-focus locale="pt-PT"></b-datepicker>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div class="px-5 pb-5">
      <div v-if="graphView == 'circle'" class="py-5">
        <ChartPie
          :key="'chart_pie_' + currentSubmetric.key"
          :chart-data="{
            labels: graphLabels, // graphLabels,
            datasets: graphDatasets, // graphDatasets,
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'left',
              labels: {
                fontColor: 'rgb(0, 0, 0)',
              },
            },
          }"
        >
        </ChartPie>
      </div>
      <div v-else-if="graphView == 'bars'" class="py-5">
        <ChartHorizontalBar
          :key="'chart_bar_' + currentSubmetric.key"
          :chart-data="{
            labels: graphLabels, // graphLabels,
            datasets: graphDatasets, // graphDatasets,
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'left',
              labels: {
                fontColor: 'rgb(0, 0, 0)',
              },
            },
          }"
        >
        </ChartHorizontalBar>
      </div>
      <div v-if="timeseries" class="pb-3 pt-5">
        <ChartLine
          :chart-data="{
            labels: graphDays,
            datasets: graphDatasets,
          }"
          :options="{
            animation: {
              duration: 0, // general animation time
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            //responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            elements: {
              point: {
                radius: 3,
                hoverRadius: 4,
                hitRadius: 6,
              },
              line: {
                tension: 0.4,
              },
            },
            legend: {
              position: 'bottom',
            },
            datasetStrokeWidth: 2,
            tooltipFillColor: 'rgba(0,0,0,0.8)',
            tooltipFontStyle: 'bold',
          }"
        ></ChartLine>
      </div>
      <div
        v-else-if="false"
        class="grid gap-4 pt-4"
        :class="{ 'grid-cols-6': metric.extrametrics.length <= 18, 'grid-cols-4': metric.extrametrics.length > 18 }"
      >
        <div
          v-for="(metr, m) in metric.extrametrics"
          :key="'record_' + m"
          class="text-left cursor-pointer pt-4 pb-3 px-4 text-base font-semibold bg-white rounded-lg shadow-sm"
          :class="{
            'ring-primary ring-inset ring-2 ring-opacity-60':
              currentSubmetric != null && metr.key == currentSubmetric.key,
            'hover:ring-primary hover:ring-inset hover:ring-1 hover:ring-opacity-40':
              currentSubmetric == null || metr.key != currentSubmetric.key,
            'flex items-center': metric.extrametrics.length > 18,
          }"
          @click="selectMetric(metr)"
        >
          <div
            class="overflow-hidden whitespace-normal leading-5 overflow-ellipsis break-all text-gray-700"
            :class="{
              'h-10 max-h-10 mb-3': metric.extrametrics.length <= 18,
              'flex-1': metric.extrametrics.length > 18,
            }"
          >
            {{ metr['title'][language] ? metr['title'][language] : $t('no_translation') }}
          </div>
          <div class="text-3xl font-normal">{{ metricsCache[metr.key].total ?? 0 }}</div>
        </div>
      </div>
      <div v-if="false" class=" text-xs text-gray-500">
        {{ $t('last_update') }} {{ dayjs(metricsCache[currentSubmetric.key].last_updated).format('DD/MM/YYYY HH:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import ChartLine from '@/fw-modules/fw-core-vue/ui/components/charts/ChartLine.vue'
import ChartPie from '@/fw-modules/fw-core-vue/ui/components/charts/ChartPie.vue'
import ChartHorizontalBar from '@/fw-modules/fw-core-vue/ui/components/charts/ChartHorizontalBar.vue'
import dayjs from 'dayjs'
import Vue from 'vue'
import ServiceAnalytics from '../../services/ServiceAnalytics'
export default {
  name: 'DashboardPanel',
  components: { ChartLine, ChartPie, ChartHorizontalBar },
  props: {
    metrics: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      default: 'pt',
    },
  },
  data() {
    return {
      labels: {
        'electricity:consumption': 'Consumo de eletricidade',
        'electricity:production': 'Produção de eletricidade',
        'water:consumption': 'Consumo de água',
        'water:production': 'Produção de água',
        'gas:consumption': 'Consumo de gás',
        'gas:production': 'Produção de gás',
      },
      graphView: 'numbers',
      currentSubmetric: null,
      metricsCache: {},
      tmpMetricsCache: {}, //used for date ranges
      loading: true,
      timeseries: true,
      showDateRangePicker: false,
      dates: [],
      showAllDataSameTime: false,
      availableGraphColors: [
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#e6194B',
        '#3cb44b',
        '#ffe119',
        '#4363d8',
        '#f58231',
        '#911eb4',
        '#42d4f4',
        '#f032e6',
        '#bfef45',
        '#fabed4',
        '#469990',
        '#dcbeff',
        '#9A6324',
        '#fffac8',
        '#800000',
        '#aaffc3',
        '#808000',
        '#ffd8b1',
        '#000075',
        '#a9a9a9',
        '#000000',
      ],
      graphLabels: [],
      allowCircleGraph: false,
      granularity: null,
    }
  },
  computed: {
    graphDatasets() {
      let self = this
      return this.metrics.map(d => {
        let color = this.getGraphColor(d.title)
        let startColor = this.getGraphColor(d.title, 0.3)
        let transparentColor = color + '00'
        return {
          label: d.title,
          data: d.data.map(d => d.consumption),
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color,
          backgroundColor: function(context) {
            const chart = context.chart
            const { ctx, chartArea } = chart
            if (!chartArea) {
              // This case happens on initial chart load
              return
            }
            return self.getGradient(ctx, chartArea, startColor, transparentColor)
          }, //this.getGraphColor(d.title, 0.5),
          borderWidth: 2,
        }
      })
      /*return [
        {
          label: this.metric.title,
          data: this.metric.data.map(d => d.raw_value),
          //backgroundColor:  //'rgba(255, 99, 132, 0.2)',
          borderColor: this.getGraphColor(this.metric.key.toString()), // 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
        },
      ]*/
    },
    graphDays() {
      let days = []
      //verify if date is different
      //let lastDay = null
      this.metrics[0].data.forEach(d => {
        let [day, hour] = d.timestamp.split(' ')
        /*
        let hour = d.timestamp.split(' ')[1]

        let label = ''
        if (lastDay == null || day !== lastDay) {
          if (lastDay != null) {
            label = day + ' '
          }
          lastDay = day
        }
        label += hour*/
        let reversedDay = day
          .split('-')
          .reverse()
          .join('/')
        //console.log('day', day, reversedDay)
        days.push(reversedDay + ' ' + (hour ? hour : ''))
      })
      return days
    },
    totalSum() {
      return Object.values(this.metricsCache).reduce((a, b) => a + b.total, 0)
    },
    metricKey() {
      return this.metrics[0].type + ':' + this.metrics[0].category
    },
    showDateRange() {
      return this.timeseries
    },
    api() {
      return ServiceAnalytics
    },
  },
  methods: {
    changeGraphView(view) {
      console.log('changeGraphView', view)
      if (view !== 'numbers') {
        this.processGraphData(view)
      }
      Vue.nextTick(() => {
        this.graphView = view
      })
    },
    /*processGraphData(view) {
      if (!this.timeseries && view === 'bars') {
        this.graphDatasets = [
          {
            //label: 'Dataset',
            data: JSON.parse(JSON.stringify(this.metricsData)).map(option => {
              return option.data['records'] // this.labels['sub_metrics'][option.code].records
              return {
            label: this.labels['sub_metrics'][option.code].title[this.language],
            data: this.graphTimeseriesData(option.key),
            backgroundColor: this.getGraphColor(option.code),
          }
            }),
            backgroundColor: JSON.parse(JSON.stringify(this.metricsData)).map(option => {
              return this.getGraphColor(option.type)
            }),
          },
        ]
        this.graphLabels = JSON.parse(JSON.stringify(this.metricsData)).map(option => {
          // return option.data?.title[this.language] ?? 'Sem legenda' //
          return this.labels['sub_metrics'][option.type].title[this.language]
        })
      } else if (!this.timeseries && view === 'circle') {
        //ignore totals
        this.graphDatasets = [
          {
            //label: 'Dataset',
            data: JSON.parse(JSON.stringify(this.metricsData))
              .map(option => {
                if (option.type === 'total') return 'TOTAL'
                return option.data['records'] // this.labels['sub_metrics'][option.code].records
                /*return {
            label: this.labels['sub_metrics'][option.code].title[this.language],
            data: this.graphTimeseriesData(option.key),
            backgroundColor: this.getGraphColor(option.code),
          }
              })
              .filter(function(element) {
                return element !== 'TOTAL'
              }),
            backgroundColor: JSON.parse(JSON.stringify(this.metricsData))
              .map(option => {
                if (option.type === 'total') return 'TOTAL'
                return this.getGraphColor(option.type)
              })
              .filter(function(element) {
                return element !== 'TOTAL'
              }),
          },
        ]
        this.graphLabels = JSON.parse(JSON.stringify(this.metricsData))
          .map(option => {
            // return option.data?.title[this.language] ?? 'Sem legenda'
            return this.labels['sub_metrics'][option.type].title[this.language]
          })
          .filter(function(element) {
            return element.toLowerCase() !== 'total'
          })
      } else if (this.timeseries) {
        /*if (this.showAllDataSameTime) {
          this.graphDatasets = this.submetricsOptions.map(option => {
            return {
              label: this.labels['sub_metrics'][option.code].title[this.language],
              data: this.graphTimeseriesData(option.key),
              //backgroundColor:  //'rgba(255, 99, 132, 0.2)',
              borderColor: this.getGraphColor(option.code), // 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
            }
          })
        } else {
          let values = this.metric.data.map(d => d.raw_value)
          console.log('values', values)
          console.log('labels', this.graphDays)
        this.graphDatasets = [
          {
            label: this.metric.title,
            data: this.metric.data.map(d => d.raw_value),
            //backgroundColor:  //'rgba(255, 99, 132, 0.2)',
            borderColor: this.getGraphColor(this.metric.key.toString()), // 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
          },
        ]
        //}
      }
    },*/
    getGraphColor(code, alpha = 1) {
      var sum = 0
      for (let i in code) {
        sum += code.charCodeAt(i)
      }
      let hexColor = this.availableGraphColors[sum % this.availableGraphColors.length]
      return hexColor + (alpha < 1 ? Math.round(alpha * 255).toString(16) : '')
    },
    getGradient(ctx, chartArea, color, transparentColor) {
      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top
      let gradient = transparentColor
      let width = 0
      let height = 0
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth
        height = chartHeight
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
        gradient.addColorStop(0, transparentColor)
        //gradient.addColorStop(0.5, Utils.CHART_COLORS.yellow);
        gradient.addColorStop(1, color)
      }

      return gradient
    },
    graphTimeseriesData(metricKey) {
      //if (!this.timeseries) return []
      return Object.keys(this.metricsCache[metricKey].indexes)
        .sort()
        .map(day => {
          return this.metricsCache[metricKey].indexes[day]
        })
    },
    deleteDateRange() {
      this.dates = []
      this.showDateRangePicker = false
    },
    toogleDateRangePicker() {
      this.showDateRangePicker = !this.showDateRangePicker
    },
    selectMetric(metric) {
      //this.showAllDataSameTime = false
      this.currentSubmetric = metric
      //this.getMetricData(metric.key)
    },
    seeAllData() {
      this.currentSubmetric = null
      /*this.showAllDataSameTime = true
      if (this.timeseries) {
        this.showAllDataSameTime = true
        this.getAllMetrics(true)
      } else {
        this.getMetricData(this.currentSubmetric.key, true)
      }*/
    },
    dayjs(value) {
      return dayjs(value)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "all": "Todos",
    "last_update": "Última atualização a",
    "error": "Ocorreu um erro ao carregar os dados",
    "no_translation": "Sem tradução",
    "available_metrics": "Métricas disponíveis"
  },
  "en": {
    "all": "Todos",
    "last_update": "Last update at",
    "error": "Ocorreu um erro ao carregar os dados",
    "no_translation": "No translation",
    "available_metrics": "Available metrics"
  }
}
</i18n>
