<script>
import { Pie, mixins } from 'vue-chartjs'

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'left',
            labels: {
              fontColor: 'rgb(255, 255, 255)',
            },
          },
        }
      },
    },
  },
  mounted() {
    console.log('rendering chart with chartData', this.chartData)
    this.renderChart(this.chartData, this.options)
  },
  /*watch: {
    chartData: {
      handler() {
        console.log('chartData changed', this.chartData)
        this.renderChart(this.chartdata, this.options)
      },
      deep: true,
    },
  },*/
}
</script>
